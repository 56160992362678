import { graphql, useStaticQuery } from 'gatsby'
import get from 'lodash/get'
import React from 'react'

export default function Testimonials() {
  const data = useStaticQuery(graphql`
    query LatestTestimonialsQuery {
      allContentfulTestimonial(
        sort: { fields: [createdAt], order: DESC }
        limit: 2
      ) {
        nodes {
          quote {
            quote
          }
          name
          position
        }
      }
    }
  `)

  const testimonials = get(data, 'allContentfulTestimonial.nodes', null)

  return (
    <section className="bg-ufblack">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          {testimonials.map((testimonial, index) => (
            <div
              className={`flex flex-col py-8 sm:pb-32 lg:px-8 xl:pr-20 ${
                (index + 1) % 2 === 0
                  ? 'border-0 lg:border-gray-500 lg:border-l-2'
                  : null
              }`}
            >
              <figure className="mt-4 md:mt-10 flex flex-auto flex-col justify-between">
                <blockquote className="text-lg leading-8 text-white">
                  <p>{testimonial.quote.quote}</p>
                </blockquote>
                <figcaption className="mt-10 flex items-center gap-x-6">
                  <div className="text-base">
                    <div className="font-semibold text-white">
                      {testimonial.name}
                    </div>
                    <div className="mt-1 text-uforange-600">
                      {testimonial.position}
                    </div>
                  </div>
                </figcaption>
              </figure>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
