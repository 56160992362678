import { graphql, useStaticQuery } from 'gatsby'
import get from 'lodash/get'
import React from 'react'
import Icon from '../common/Icon'

export default function OffersGrid() {
  const data = useStaticQuery(graphql`
    query BenefitsQuery {
      allContentfulBenefits(sort: { fields: [order], order: ASC }) {
        nodes {
          title
          description {
            description
          }
          page
          icon
        }
      }
    }
  `)

  const fetchedBenefits = get(data, 'allContentfulBenefits.nodes', null)

  const benefits = fetchedBenefits.filter((benefit) => benefit.page === 'UEone')

  return (
    <div className="bg-uforange-600">
      <div className="mx-auto max-w-4xl px-4 py-16 sm:px-6 sm:pt-20 sm:pb-24 lg:max-w-7xl lg:px-8 lg:pt-24">
        <h2 className="text-3xl font-bold tracking-tight text-white">
          Key Benefits
        </h2>
        <p className="mt-4 text-xl tracking-tight text-white">
          Tailored to meet your communities needs
        </p>
        <div className="mt-12 grid grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-2 lg:mt-16 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-16">
          {benefits.map((benefit) => (
            <div key={benefit.title}>
              <span className="flex h-12 w-12 items-center justify-center rounded-md bg-uforange-300">
                <Icon
                  className="h-6 w-6 text-white"
                  aria-hidden="true"
                  name={benefit.icon}
                />
              </span>
              <div className="mt-3">
                <h3 className="text-lg font-medium text-white">
                  {benefit.title}
                </h3>
                <p className="mt-2 text-sm text-white">
                  {benefit.description.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
