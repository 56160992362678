import { BLOCKS } from '@contentful/rich-text-types'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import get from 'lodash/get'
import React from 'react'

export default function ProductRange() {
  const data = useStaticQuery(graphql`
    query ChargePointQuery {
      allContentfulChargePoint {
        nodes {
          title
          description {
            raw
          }
          image {
            gatsbyImage(layout: FULL_WIDTH, width: 400)
          }
          attributeOne
          attributeTwo
          attributeThree
          attributeFour
          attributeFive
        }
      }
    }
  `)
  const products = get(data, 'allContentfulChargePoint.nodes', null)

  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const { gatsbyImage } = node.data.target
        return (
          <GatsbyImage
            image={getImage(gatsbyImage)}
            alt={'Chargepoint image'}
          />
        )
      },
    },
  }

  return (
    <div className="flex flex-col my-24 max-w-7xl mx-auto px-6 ">
      <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
        Our other chargepoints
      </h2>
      <p className="mt-6 w-full text-lg leading-8 text-gray-600 max-w-4xl">
        When it comes to planning EV charging infrastructure it’s not a one size
        fits all job. To meet the unique needs of each local authority and the
        communities they serve we offer a range of charging solutions.
      </p>
      <div className="md:grid grid-cols-1 xl:grid-cols-2 my-10 w-full max-w-7xl mx-auto">
        {products.map((product) => (
          <div className="mx-auto lg:mx-0 py-4 md:py-8 flex flex-row items-center">
            <div className="flex flex-col">
              <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                {product.title}
              </h2>
              <span className="mt-4 text-lg text-gray-500">
                {renderRichText(product.description, options)}
              </span>
              <ul className="py-6 space-y-2">
                {product.attributeOne && (
                  <li className="ml-1 text-gray-500">{product.attributeOne}</li>
                )}
                {product.attributeTwo && (
                  <li className="ml-1 text-gray-500">{product.attributeTwo}</li>
                )}
                {product.attributeThree && (
                  <li className="ml-1 text-gray-500">
                    {product.attributeThree}
                  </li>
                )}
                {product.attributeFour && (
                  <li className="ml-1 text-gray-500">
                    {product.attributeFour}
                  </li>
                )}
                {product.attributeFive && (
                  <li className="ml-1 text-gray-500">
                    {product.attributeFive}
                  </li>
                )}
              </ul>
            </div>
            <GatsbyImage
              alt={`${product.title} promo image`}
              image={product.image.gatsbyImage}
              className="rounded hidden md:block w-72 h-96 object-contain lg:max-w-none"
            />
          </div>
        ))}
      </div>
    </div>
  )
}
