import React from 'react'
import Header from '../components/common/Header'
import Testimonials from '../components/common/Testimonials.js'
import DownloadCTA from '../components/cta/DownloadCTA.js'
import GeneralCTA from '../components/cta/GeneralCTA'
import Layout from '../components/layout'
import Seo from '../components/seo'
import OffersGrid from '../components/solutions/OffersGrid.js'
import ProductRange from '../components/solutions/ProuctRange.js'

class RootIndex extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <Seo
          title="Charging Solutions | Urban Fox | EV Charging"
          description="We offer a comprehensive range of slow, fast and rapid on-street and car park chargepoints"
        />
        <Header filter="Charging Solutions" />
        <div className="lg:grid lg:grid-cols-2 lg:gap-24 my-24 max-w-7xl mx-auto px-6">
          <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-none lg:py-16">
            <div>
              <div className="mt-6">
                <div className="flex flex-col gap-2">
                  <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                    The UEone
                  </h2>
                  <p className="text-uforange-600 font-bold">
                    The scalable solution to on-street charging
                  </p>
                </div>
                <p className="mt-4 text-lg text-gray-500">
                  Our flagship retractable chargepoint is the perfect solution
                  for residents without driveways. When not in use, the fast
                  charger auto-retracts flush underground, leaving pavements
                  clutter-free and accessible for everyone.
                </p>
              </div>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:mt-0">
            <div
              className={'pl-6 md:-mr-16 lg:relative lg:m-0 lg:h-full lg:px-0'}
            >
              <img
                className="hidden sm:block md:w-144 xl:w-full rounded lg:absolute lg:h-full lg:w-auto lg:max-w-none"
                src={'/images/common/ue-one.gif'}
                alt={'UEone'}
              />
            </div>
          </div>
        </div>
        <OffersGrid />
        <Testimonials />
        <ProductRange />
        <GeneralCTA />
        <DownloadCTA title="View the full range in our sales brochure" />
      </Layout>
    )
  }
}

export default RootIndex
